<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import deepClone from 'lodash.clonedeep';
import { hasSchema } from '@/utils';

const EMPTY_RANGE = {
  rangeId: '',
  force: false,
  matchMode: '',
  rangeType: '',
  environment: '',
  instance: '',
  catalog: '',
  schema: '',
  table: '',
  view: '',
  column: '',
  nodes: '',
  chooseAll: ''
};

const RANGE_FORM_KEY_MAP = {
  environment: 'envId',
  instance: 'dsId',
  catalog: 'catalog',
  schema: 'schema',
  table: 'table',
  view: 'view',
  column: 'column'
};

const RANGE_RES_KEY_MAP = {
  environment: 'env',
  instance: 'ds',
  catalog: 'catalog',
  schema: 'schema',
  table: 'table',
  view: 'view',
  column: 'column'
};

const RANGE_FORM_VALIDATE = {
  environment: [{ required: true, message: '环境不能为空' }],
  instance: [{ required: true, message: '实例不能为空' }],
  catalog: [{ required: true, message: '库不能为空' }],
  schema: [{ required: true, message: '模式不能为空' }],
  table: [{ required: true, message: '表不能为空' }],
  view: [{ required: true, message: '视图不能为空' }],
  column: [{ required: true, message: '列不能为空' }]
};

export default {
  name: 'ruleRange',
  data() {
    return {
      scopeList: [],
      matchModeList: [],
      dsType: '',
      selectedScope: {},
      selectedRange: {},
      forceEvent: null,
      showForceRuleModal: false,
      forceRuleModalTitle: '',
      forceRuleModalText: '',
      forceRuleRefererList: [],
      forceRuleRefererColumns: [
        {
          title: this.$t('huan-jing-ming-cheng'),
          key: 'envName'
        },
        {
          title: this.$t('huan-jing-miao-shu'),
          key: 'envDesc'
        }
      ],
      loading: false,
      columnSelectTableOrView: 'Table',
      dsHasSchema: false,
      specId: null,
      ruleId: null,
      ruleName: '',
      ruleKind: '',
      SCOPE_KEY: {},
      scopeSetting: [],
      rangeForm: deepClone(EMPTY_RANGE),
      rangeFormValidate: {},
      isEditRange: false,
      showAddRangeModal: false,
      specName: '',
      pageSize: 20,
      pageNum: 1,
      total: 0,
      search: '',
      rangeList: [],
      showRangeList: [],
      rangeColumns: [
        {
          title: '范围',
          slot: 'rangeType',
          width: 200
        },
        {
          title: '匹配模式',
          slot: 'matchMode',
          width: 200
        },
        {
          title: '设置值',
          key: 'desc'
        },
        {
          title: this.$t('cao-zuo'),
          slot: 'rangeAction',
          width: 120,
          fixed: 'right'
        }
      ],
      envList: []
    };
  },
  computed: {
    ...mapGetters(['getMatchMode', 'getScopeByMatchMode', 'getMatchModeList', 'getScopeListByMatchMode']),
    ...mapState(['myAuth']),
    breadcrumbList() {
      return [
        { label: this.$t('gui-fan-lie-biao'), to: '/dmspeclist', key: 'one' },
        { label: this.specName, to: { path: `/dmspec/${this.specId}`, query: { ruleKind: this.ruleKind } }, key: 'two' },
        { label: `${this.ruleName}的范围列表`, key: 'three' }
      ];
    },
    scopeSelectList() {
      return this.scopeList.filter((scope) => !scope.hidden);
    }
  },
  async mounted() {
    this.specId = this.$route.params.specId || null;
    this.ruleId = this.$route.params.ruleId || null;
    this.specName = this.$route.query.specName || '';
    this.ruleKind = this.$route.query.ruleKind || '';
    this.ruleName = this.$route.query.ruleName || '';
    await this.getRuleSetting();
    await this.getSpecRuleRange();
  },
  methods: {
    ...mapActions(['getRuleSetting']),
    handleMatchModeChange() {
      this.scopeList = this.getScopeListByMatchMode(this.ruleKind, this.rangeForm.matchMode);
      const scopeSelectList = this.scopeList.map((scope) => !scope.hidden);
      this.rangeForm = {
        ...this.rangeForm,
        rangeType: '',
        environment: '',
        instance: '',
        catalog: '',
        schema: '',
        table: '',
        column: '',
        nodes: '',
        chooseAll: ''
      };
      this.rangeForm.rangeType = this.scopeSelectList[0].name;
      this.$refs.rangeForm.resetFields();
      this.generateScopeSetting(this.rangeForm.rangeType);
    },
    handleScopeChange(scopeName) {
      if (!scopeName) {
        return;
      }
      this.rangeForm = {
        ...this.rangeForm,
        environment: '',
        instance: '',
        catalog: '',
        schema: '',
        table: '',
        column: '',
        nodes: '',
        chooseAll: ''
      };
      this.$refs.rangeForm.resetFields();
      this.generateScopeSetting(scopeName);
    },
    async handleCloseModal() {
      this.selectedRange = {};
      this.showAddRangeModal = false;
      this.showForceRuleModal = false;
    },
    async handleDeleteRange(range, isForce = false) {
      this.selectedRange = range;

      const deleteRange = async (force) => {
        const res = await this.$services.specDeleteRange({
          data: {
            specId: this.specId,
            rangeId: this.selectedRange.rangeId,
            force
          }
        });

        if (res.success) {
          if (res.data) {
            if (res.data.success) {
              this.$Message.success(res.data.message);
              await this.handleCloseModal();
              await this.getSpecRuleRange();
            } else {
              this.selectedRange = range;
              this.forceEvent = deleteRange;
              this.showForceRuleModal = true;
              this.forceRuleModalTitle = this.$t('shan-chu-fan-wei');
              this.forceRuleModalText = res.data.message;
              this.forceRuleRefererList = res.data.referer;
            }
          }
        }
      };

      await deleteRange(isForce);
    },
    async handleAddRange(force = false) {
      this.$refs.rangeForm.validate(async (valid) => {
        if (valid) {
          const lastKey = this.scopeSetting[this.scopeSetting.length - 1].key;
          if (this.rangeForm.matchMode === 'EXACT') {
            this.rangeForm.nodes = [...this.rangeForm[lastKey]];
          } else {
            this.rangeForm.nodes = [this.rangeForm[lastKey]];
          }
          const res = await this.$services.specSaveRange({
            data: {
              ...this.rangeForm,
              force,
              envId: this.rangeForm.environment,
              dsId: this.rangeForm.instance,
              specId: this.specId,
              ruleId: this.ruleId,
              ruleKind: this.ruleKind,
              [RANGE_FORM_KEY_MAP[lastKey]]: null
            }
          });

          if (res.success) {
            this.showRuleDetailModal = false;
            if (res.data) {
              if (res.data.success) {
                this.$Message.success(res.data.message);
                await this.handleCloseModal();
                await this.getSpecRuleRange();
              } else {
                this.forceEvent = this.handleAddRange;
                this.showForceRuleModal = true;
                this.forceRuleModalTitle = this.isEditRange ? this.$t('bian-ji-fan-wei') : this.$t('xin-jian-fan-wei');
                this.forceRuleModalText = res.data.message;
                this.forceRuleRefererList = res.data.referer;
              }
            }
          }
        }
      });
    },
    handleShowAddRangeModal(isEditRange = false, range) {
      try {
        this.isEditRange = isEditRange;
        this.matchModeList = this.getMatchModeList(this.ruleKind);
        this.rangeForm = deepClone(EMPTY_RANGE);
        if (isEditRange) {
          if (range) {
            this.selectedRange = range;
            const {
              rangeId, matchMode, rangeType, env, ds, catalog, schema, table, view, column, nodes, dsType, chooseAll
            } = range;
            this.rangeForm = {
              rangeId,
              force: false,
              matchMode,
              rangeType,
              environment: env ? env.value : '',
              instance: ds ? ds.value : '',
              catalog: catalog ? catalog.value : '',
              schema: schema ? schema.value : '',
              table: table ? table.value : '',
              view: view ? view.value : '',
              column: '',
              nodes: '',
              chooseAll
            };

            this.scopeList = this.getScopeListByMatchMode(this.ruleKind, this.rangeForm.matchMode);

            if (dsType) {
              this.dsType = dsType;
              this.dsHasSchema = hasSchema(dsType);
            }
            if (matchMode === 'EXACT') {
              this.rangeForm[rangeType.toLowerCase()] = nodes.map((node) => node.value);
            } else {
              this.rangeForm[rangeType.toLowerCase()] = nodes[0].value;
            }
          }
        } else {
          this.rangeForm.matchMode = this.matchModeList[0].name;
          this.scopeList = this.getScopeListByMatchMode(this.ruleKind, this.rangeForm.matchMode);
          this.rangeForm.rangeType = this.scopeList[0].name;
        }

        this.generateScopeSetting(this.rangeForm.rangeType);
        this.showAddRangeModal = true;
      } catch (e) {
        console.log(e);
      }
    },
    generateScopeSetting(scopeName) {
      this.rangeForm.rangeType = scopeName;
      this.columnSelectTableOrView = 'Table';
      const scopeSetting = [];
      for (let i = 0; i < this.scopeList.length; i++) {
        const scope = this.scopeList[i];
        const scopeItem = {
          ...scope,
          loading: false,
          loaded: false,
          key: scope.name.toLowerCase(),
          list: [],
          nextKey: '',
          nextName: '',
          multi: false,
          last: false
        };

        if (this.selectedRange[RANGE_RES_KEY_MAP[scopeItem.key]]) {
          scopeItem.list = [this.selectedRange[RANGE_RES_KEY_MAP[scopeItem.key]]];
        }

        if (scope.name === this.rangeForm.rangeType) {
          scopeItem.multi = true;
          scopeItem.last = true;
          if (this.selectedRange.nodes) {
            scopeItem.list = this.selectedRange.nodes;
          }
        }

        scopeItem.list = scopeItem.list.map((item) => ({
          objId: item.value,
          objName: item.name
        }));

        scopeSetting.push(scopeItem);

        if (scope.name === this.rangeForm.rangeType) {
          break;
        }
      }

      if (scopeName === 'View') {
        const scopeTableIndex = scopeSetting.findIndex((scope) => scope.name === 'Table');
        if (scopeTableIndex > -1) {
          scopeSetting.splice(scopeTableIndex, 1);
        }
      }

      if (scopeName === 'Column') {
        const scopeViewIndex = scopeSetting.findIndex((scope) => scope.name === 'View');
        if (scopeViewIndex > -1) {
          scopeSetting.splice(scopeViewIndex, 1);
        }
        const scopeTableIndex = scopeSetting.findIndex((scope) => scope.name === 'Table');
        if (scopeTableIndex > -1) {
          scopeSetting[scopeTableIndex].i18n = '选择表或视图';
        }
      }

      for (let i = 0; i < scopeSetting.length - 1; i++) {
        if (scopeSetting[i].key === 'table') {
          scopeSetting[i].nextKey = 'column';
          scopeSetting[i].nextName = 'Column';
        } else {
          scopeSetting[i].nextKey = scopeSetting[i + 1].key;
          scopeSetting[i].nextName = scopeSetting[i + 1].name;
        }
      }

      const validate = {};
      scopeSetting.forEach((scope) => {
        validate[scope.key] = RANGE_FORM_VALIDATE[scope.key];
      });

      this.rangeFormValidate = validate;

      this.scopeSetting = scopeSetting;

      this.handleGetList();
    },
    handleColumnTableOrViewChange(name) {
      const len = this.scopeSetting.length;
      this.rangeForm.view = '';
      this.rangeForm.table = '';
      this.rangeForm.column = [];
      this.handleGetList(this.scopeSetting[len - 3], { value: this.rangeForm.schema });
    },
    handleSelectOpenChange(scope, force, open) {
      if ((!scope.loaded || force) && open) {
        scope.loaded = true;
        const {
          key
        } = scope;
        const {
          environment, instance, catalog, schema, table, view, column
        } = this.rangeForm;

        console.log(key);

        const levels = [];
        switch (key) {
          case 'environment':
            this.handleListEnv(key);
            break;
          case 'instance':
            if (environment) {
              this.handleListIns(key, { envId: environment });
            }
            break;
          case 'catalog':
            if (environment && instance) {
              this.handleListCatalogOrSchema(this.dsHasSchema ? key : 'schema', { levels: [environment, instance] });
            }
            break;
          case 'schema':
            if (this.dsHasSchema) {
              if (environment && instance && catalog) {
                this.handleListCatalogOrSchema(key, { levels: [environment, instance, catalog] });
              }
            } else {
              if (environment && instance) {
                this.handleListCatalogOrSchema(key, { levels: [environment, instance] });
              }
            }
            break;
          case 'table':
          case 'view':
            if (this.dsHasSchema) {
              if (environment && instance && catalog && schema) {
                this.handleListTableOrView(key, {
                  levels: [environment, instance, catalog, schema],
                  leafType: this.rangeForm.rangeType
                });
              }
            } else {
              if (environment && instance && schema) {
                this.handleListTableOrView(key, {
                  levels: [environment, instance, schema],
                  leafType: this.rangeForm.rangeType
                });
              }
            }
            break;
          case 'column':
            if (this.dsHasSchema) {
              if (environment && instance && catalog && schema && this.rangeForm[this.columnSelectTableOrView.toLowerCase()]) {
                this.handleListColumn(key,
                  {
                    levels: [environment, instance, catalog, schema],
                    targetType: this.columnSelectTableOrView,
                    targetName: this.rangeForm[this.columnSelectTableOrView.toLowerCase()]
                  });
              }
            } else {
              if (environment && instance && schema && this.rangeForm[this.columnSelectTableOrView.toLowerCase()]) {
                this.handleListColumn(key,
                  {
                    levels: [environment, instance, schema],
                    targetType: this.columnSelectTableOrView,
                    targetName: this.rangeForm[this.columnSelectTableOrView.toLowerCase()]
                  });
              }
            }
            break;
          default:
            break;
        }
      }
    },
    handleGetList(scope = { nextKey: 'environment' }, selectedItem = {}) {
      scope.loaded = true;
      const name = selectedItem.value;
      const {
        nextKey, key, loaded
      } = scope;
      if (!loaded || !nextKey || name === this.rangeForm[key]) {
        return;
      }
      const {
        environment, instance, catalog, schema, table, view, column
      } = this.rangeForm;

      if (key === 'instance') {
        let dsHasSchema = false;
        scope.list.forEach((item) => {
          if (item.objId === instance && hasSchema(item.objAttr.dsType)) {
            this.dsType = item.objAttr.dsType;
            dsHasSchema = true;
          }
        });

        this.dsHasSchema = dsHasSchema;
      }

      const levels = [];
      switch (nextKey) {
        case 'environment':
          this.handleListEnv(nextKey);
          break;
        case 'instance':
          this.rangeForm = {
            ...this.rangeForm,
            instance: '',
            catalog: '',
            schema: '',
            table: '',
            view: '',
            column: '',
            nodes: '',
            chooseAll: ''
          };
          if (name) {
            this.handleListIns(nextKey, { envId: name });
          }
          break;
        case 'catalog':
          this.rangeForm = {
            ...this.rangeForm,
            catalog: '',
            schema: '',
            table: '',
            view: '',
            column: '',
            nodes: '',
            chooseAll: ''
          };
          if (environment && name) {
            this.handleListCatalogOrSchema(this.dsHasSchema ? nextKey : 'schema', { levels: [environment, name] });
          }
          break;
        case 'schema':
          this.rangeForm = {
            ...this.rangeForm,
            schema: '',
            table: '',
            view: '',
            column: '',
            nodes: '',
            chooseAll: ''
          };
          if (environment && instance && name) {
            this.handleListCatalogOrSchema(nextKey, { levels: [environment, instance, name] });
          }
          break;
        case 'table':
        case 'view':
          this.rangeForm = {
            ...this.rangeForm,
            table: '',
            view: '',
            column: '',
            nodes: '',
            chooseAll: ''
          };
          if (this.dsHasSchema) {
            if (environment && instance && catalog && name) {
              this.handleListTableOrView(nextKey, {
                levels: [environment, instance, catalog, name],
                leafType: this.rangeForm.rangeType
              });
            }
          } else {
            if (environment && instance && name) {
              this.handleListTableOrView(nextKey, {
                levels: [environment, instance, name],
                leafType: this.rangeForm.rangeType
              });
            }
          }
          break;
        case 'column':
          this.rangeForm = {
            ...this.rangeForm,
            column: '',
            nodes: '',
            chooseAll: false
          };

          if (this.dsHasSchema) {
            if (environment && instance && catalog && schema && name) {
              this.handleListColumn(nextKey,
                {
                  levels: [environment, instance, catalog, schema],
                  targetType: this.columnSelectTableOrView,
                  targetName: name
                });
            }
          } else {
            if (environment && instance && schema && name) {
              this.handleListColumn(nextKey,
                {
                  levels: [environment, instance, schema],
                  targetType: this.columnSelectTableOrView,
                  targetName: name
                });
            }
          }
          break;
        default:
          break;
      }
    },
    async getSpecRuleRange() {
      this.loading = true;
      const res = await this.$services.specFetchRange({
        data: {
          ruleId: this.ruleId,
          specId: this.specId,
          ruleKind: this.ruleKind
        }
      });

      this.loading = false;
      if (res.success) {
        this.rangeList = res.data;
        this.total = res.data.length;
        this.setTableShowData();
      }
    },
    async handleListEnv(type, data = {}) {
      const res = await this.$services.rangeListEnv();
      if (res.success) {
        this.scopeSetting.forEach((scope) => {
          if (scope.key === type) {
            scope.list = res.data;
          }
        });
      }
    },
    async handleListIns(type, data) {
      const res = await this.$services.rangeListIns({
        data
      });
      if (res.success) {
        this.scopeSetting.forEach((scope) => {
          if (scope.key === type) {
            scope.list = res.data.map((item) => ({
              ...item,
              disabled: this.rangeForm.rangeType === 'Catalog' ? !hasSchema(item.objAttr.dsType) : false
            }));
          }
        });
      }
    },
    async handleListCatalogOrSchema(type, data) {
      const res = await this.$services.rangeListLevels({
        data
      });
      if (res.success) {
        this.scopeSetting.forEach((scope) => {
          if (scope.key === type) {
            scope.list = res.data;
          }
        });
      }
    },
    async handleListTableOrView(type, data) {
      if (this.rangeForm.rangeType === 'Column') {
        data.leafType = this.columnSelectTableOrView;
        type = 'table';
      }
      const res = await this.$services.rangeListLeaf({
        data
      });

      if (res.success) {
        this.scopeSetting.forEach((scope) => {
          if (scope.key === type) {
            scope.list = res.data;
          }
        });
      }
    },
    async handleListColumn(type, data) {
      const res = await this.$services.rangeListColumn({
        data
      });

      if (res.success) {
        this.scopeSetting.forEach((scope) => {
          if (scope.key === type) {
            scope.list = res.data;
          }
        });
      }
    },
    handlePageChange(pageNum) {
      this.pageNum = pageNum;
      this.setTableShowData();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.handlePageChange(1);
    },
    setTableShowData() {
      const { pageNum, pageSize } = this;
      this.showRangeList = this.rangeList.slice((pageNum - 1) * pageSize, pageNum * pageSize);
    }
  }
};
</script>

<template>
  <div class="rule-range">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb v-if="breadcrumbList.length">
            <BreadcrumbItem v-for="breadcrumb in breadcrumbList" :to="breadcrumb.to"
                            :key="breadcrumb.key">{{ breadcrumb.label }}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div class="content">
          <div class="option">
            <div class="left">
              <Input v-model="search" style="width: 280px;margin-right: 10px;"></Input>
              <Button @click="getSpecRuleRange" type="primary">{{ $t('cha-xun') }}</Button>
            </div>
            <div class="right">
              <Button @click="handleShowAddRangeModal(false)" type="primary" ghost
                      style="margin-right: 10px;" icon="md-add" v-if="myAuth.includes('DM_SECRULES_MANAGE')">{{ $t('xin-jian-fan-wei') }}
              </Button>
              <Button @click="getSpecRuleRange">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table border :columns="rangeColumns" :data="showRangeList" size="small" :loading="loading">
              <template slot-scope="{ row }" slot="rangeType">
                {{ getScopeByMatchMode(ruleKind, row.matchMode, row.rangeType).i18n }}
              </template>
              <template slot-scope="{ row }" slot="matchMode">
                {{ getMatchMode(ruleKind, row.matchMode).i18n }}
              </template>
              <template slot-scope="{ row }" slot="rangeAction">
                <Button @click="handleShowAddRangeModal(true, row)" type="text" size="small" v-if="myAuth.includes('DM_SECRULES_MANAGE')">
                  {{ $t('bian-ji') }}
                </Button>
                <Poptip
                  confirm
                  transfer
                  :title="$t('que-ding-yao-shan-chu-gai-fan-wei')"
                  @on-ok="handleDeleteRange(row)"
                >
                  <Button type="text" size="small" v-if="myAuth.includes('DM_SECRULES_MANAGE')">
                    {{ $t('shan-chu') }}
                  </Button>
                </Poptip>
              </template>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <Page :total="total" show-total show-elevator @on-change="handlePageChange"
              show-sizer
              v-model="pageNum"
              :page-size="pageSize" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <Modal v-model="showAddRangeModal"
           :title="isEditRange ? $t('bian-ji-fan-wei') : $t('xin-jian-fan-wei')" :closable="false">
      <Form :label-width="90" :model="rangeForm" :rules="rangeFormValidate" ref="rangeForm">
        <FormItem :label="$t('pi-pei-mo-shi')">
          <RadioGroup v-model="rangeForm.matchMode" @on-change="handleMatchModeChange">
            <Radio v-for="mode in matchModeList" :label="mode.name"
                   :key="mode.name">{{ mode.i18n }}
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem :label="$t('fan-wei')">
          <Select v-model="rangeForm.rangeType" @on-change="handleScopeChange" clearable>
            <Option v-for="mode in scopeSelectList" :value="mode.name" :key="mode.name">
              {{ mode.i18n }}
            </Option>
          </Select>
        </FormItem>
        <FormItem v-for="scope in scopeSetting" :key="scope.name" :label="scope.i18n" :prop="scope.key">
          <div style="display: flex;align-items: center;" v-if="rangeForm.matchMode === 'EXACT' || (rangeForm.matchMode !== 'EXACT' && !scope.last)">
            <Select
              v-if="rangeForm.rangeType === 'Column' && (scope.name === 'Table' || scope.name === 'View')"
              v-model="columnSelectTableOrView"
              @on-change="handleColumnTableOrViewChange"
            >
              <Option value="Table">{{ $t('biao') }}</Option>
              <Option value="View">{{ $t('shi-tu') }}</Option>
            </Select>
            <Select v-model="rangeForm[scope.key]" :multiple="scope.multi"
                    @on-open-change="handleSelectOpenChange(scope, false, $event)"
                    @on-select="handleGetList(scope, $event)" clearable
                    :disabled="(scope.name === 'Catalog' && !dsHasSchema) || scope.loading"
            >
              <Option
                :disabled="item.disabled"
                v-for="item in scope.list"
                :value="item.objId === '-1' ? item.objName : item.objId"
                :key="`${scope.key}.${item.objId === '-1' ? item.objName : item.objId}`"
              >{{ item.objName }}
              </Option>
            </Select>
            <Icon type="ios-loading" v-if="scope.loading" size="24"></Icon>
            <Icon type="ios-refresh" v-else size="24" @click="handleSelectOpenChange(scope, true, true)"></Icon>
          </div>
          <div style="display: flex;align-items: center;" v-else>
            <Checkbox v-model="rangeForm.chooseAll">{{ $t('quan-bu') }}</Checkbox>
            <Input v-model="rangeForm[scope.key]" style="flex: 1" :placeholder="$t('qing-shu-ru-nei-rong')" v-if="!rangeForm.chooseAll"/>
          </div>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button @click="handleAddRange(false)" type="primary">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showForceRuleModal" :title="forceRuleModalTitle">
      <div class="title" v-html="forceRuleModalText" style="margin-bottom: 10px;"></div>
      <Table :columns="forceRuleRefererColumns" :data="forceRuleRefererList" size="small">
      </Table>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button type="error" @click="forceEvent(true)">{{ forceRuleModalTitle }}</Button>
      </div>
    </Modal>
  </div>
</template>

<style scoped lang="less">
.rule-range {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

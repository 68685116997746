<template>
  <div class="datasource-wrapper">
    <div class="datasource-content">
      <Breadcrumb style="margin-bottom: 14px;">
        <BreadcrumbItem>{{ $t('cha-xun-pei-zhi') }}</BreadcrumbItem>
      </Breadcrumb>
      <Tabs v-model="activeTab">
        <TabPane name="datasource" :label="$t('shu-ju-yuan')"></TabPane>
        <TabPane name="env" :label="$t('huan-jing')" v-if="!isDesktop"></TabPane>
      </Tabs>
      <div v-if="activeTab==='datasource'" class="datasource">
        <DataSourceHeader :handleSearch="getDataSourceList" :searchKey="searchKey"
                          :supportAdd="false"
                          :handleChangeSearchType="handleChangeSearchType"
                          :refreshLoading="refreshLoading"></DataSourceHeader>
        <div class="data-source-container" style="overflow: auto">
          <Table size="small" border :columns="getColumns" :data="showData" :loading="refreshLoading">
            <template slot-scope="{ row }" slot="instanceId">
              <div style="display: flex;justify-content: space-between;align-items: center">
                <div class="info">
                  <div>{{ row.instanceId }}</div>
                  <div style="color:#cccccc">{{ row.instanceDesc }}</div>
                </div>
                <Poptip placement="right" width="680" transfer>
                  <i @click="getDataSourceDetail(row)" class="iconfont iconinfo"></i>
                  <div slot="content" style="line-height: 24px">
                    <DataSourceInDetail :dataSourceDetail="sourceDetail"></DataSourceInDetail>
                  </div>
                </Poptip>
              </div>
            </template>
            <template slot-scope="{ row }" slot="action">
              <Button
                :disabled="!row.enableQuery"
                size="small" type="text"
                @click.native="handleEditParams(row)"
              >
                {{ $t('xiu-gai-can-shu-pei-zhi') }}
              </Button>
            </template>
            <template slot-scope="{ row }" slot="host">
              <div class="host-type">
                <div v-if="row.privateHost">
                  <span class="host-type-label">{{ $t('nei') }}</span>
                  {{ row.privateHost }}
                </div>
                <div v-if="row.publicHost" style="margin-top: 3px">
                  <span class="host-type-label">{{ $t('wai') }}</span>
                  {{ row.publicHost }}
                </div>
              </div>
            </template>
            <template slot-scope="{ row }" slot="dataSourceType">
              <Tooltip style="margin-left: 10px;font-size: 24px;cursor: pointer" placement="right"
                       class="dataSource-icon" transfer
                       :content="`${row.deployType==='ALIBABA_CLOUD_HOSTED'?Mapping.aliyunType[row.dataSourceType]:row.dataSourceType}`">
                <DataSourceIcon :type="row.dataSourceType"
                                :instanceType="row.deployType"></DataSourceIcon>
              </Tooltip>
            </template>
            <template slot-scope="{ row }" slot="deployType">
              {{ DATASOURCE_DEPLOY_TYPE_I18N[row.deployType] }}
            </template>
            <template slot-scope="{ row }" slot="enableQuery">
              <Tooltip placement="right" transfer :content="$t('zan-wei-kai-fang-gai-shu-ju-yuan')"
                       v-if="!ifSupported(row)">
                <i-switch disabled v-if="!ifSupported(row)" true-color="#52C41A"
                          v-model="row.enableQuery"></i-switch>
              </Tooltip>
              <i-switch v-if="ifSupported(row)" @on-change="handleEnableQuery(row)"
                        true-color="#52C41A" v-model="row.enableQuery"></i-switch>
            </template>
          </Table>
        </div>
      </div>
      <div v-else class="datasource">
        <div class="data-source-container" style="overflow: auto">
          <Table size="small" border :columns="envColumns" :data="showEnvData" :loading="refreshLoading">
            <template slot-scope="{ row }" slot="enableSpec">
              <i-switch v-model="row.secDesVO.openSec" true-color="#52C41A"
                        @on-change="handleEnableSpecChange(row, $event)"/>
              <Poptip :title="$t('bang-ding-de-gui-fan')" v-if="row.secDesVO.openSec" transfer
                      style="margin-left: 5px;" placement="right">
                <template #content>
                  <div>{{ $t('gui-fan-ming-cheng') }}：{{ row.secDesVO.name }}</div>
                  <div>{{ $t('gui-fan-miao-shu') }}：{{ row.secDesVO.desc }}</div>
                </template>
                <Icon type="ios-information-circle-outline" style="cursor:pointer;"/>
              </Poptip>
            </template>
            <template slot-scope="{ row }" slot="enableTicket">
              <i-switch v-model="row.ticketDesVO.openTicket" true-color="#52C41A"
                        @on-change="handleEnableTicketChange(row, $event)"/>
              <Poptip :title="$t('bang-ding-de-gong-dan')" v-if="row.ticketDesVO.openTicket"
                      transfer style="margin-left: 5px;" placement="right">
                <template #content>
                  <div>{{ $t('liu-cheng-lei-xing') }}：{{ row.ticketDesVO.type }}</div>
                  <div>{{ $t('mo-ban-ming-cheng') }}：{{ row.ticketDesVO.templateName }}</div>
                </template>
                <Icon type="ios-information-circle-outline" style="cursor:pointer;"/>
              </Poptip>
            </template>
          </Table>
        </div>
      </div>
    </div>
    <div class="footer-container">
      <Page :total="total" show-total show-elevator @on-change="handlePageChange" show-sizer
            :page-size="size"
            @on-page-size-change="handlePageSizeChange" v-if="activeTab==='datasource'"/>
      <Page :total="total" show-total show-elevator @on-change="handleEnvPageChange" show-sizer
            v-else
            :page-size="size"
            @on-page-size-change="handleEnvPageSizeChange"/>
    </div>
    <EnableQueryModal :visible="showEnableQueryModal" :datasource="selectedDatasource"
                      :handle-close-modal="handleCloseModal" :get-ds-list="getDataSourceList"
                      type="dataSourceList"></EnableQueryModal>
    <Modal :title="$t('shu-ju-guan-li-guan-bi-ti-shi')" v-model="showDisableQueryModal"
           @on-ok="handleDisableDsQuery" @on-cancel="handleCancelDisableQueryModal">
      <div>
        {{
          $t('que-ren-ting-yong')
        }}<b>{{ selectedDatasource.instanceId }}</b>{{
          $t('de-shu-ju-guan-li-gong-neng-jin-yong-hou-jiang-wu-fa-cha-xun-shu-ju-chu-li-gong-dan')
        }}
      </div>
    </Modal>
    <Modal v-model="showEnableTicketModal" :title="$t('qi-yong-gong-dan-qing-pei-zhi')">
      <Form ref="ticketForm" :model="ticketData" :rules="ticketDataValidate">
        <FormItem :label="$t('yin-qing')" prop="ticket_approval_type">
          <Select v-model="ticketData.ticket_approval_type" @on-change="handleApprovalTypeChange">
            <Option v-for="ticketType in ticketTypeList" :value="ticketType.approvalType"
                    :key="ticketType.approvalType">{{ ticketType.i18n }}
            </Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('mo-ban')" prop="template_id">
          <Select v-model="ticketData.template_id">
            <Option v-for="template in templateList" :value="template.templateIdentity"
                    :key="template.templateIdentity">{{ template.approTemplateName }}
            </Option>
          </Select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleCloseEnableTicketModal">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleBindTicket">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showDisableTicketModal" :title="$t('gong-dan-guan-bi-ti-shi')"
           @on-ok="handleDisableTicket" @on-cancel="handleCloseDisableTicketModal">
      {{
        $t('que-ding-bu-zai-jie-shou-lai-zi')
      }}<b>{{ this.currentEnv.envName }}</b>{{ $t('de-gong-dan-di-jiao') }}
    </Modal>
    <Modal v-model="showEnableSpecModal" :title="$t('qi-yong-an-quan-gui-fan-qing-xuan-ze')">
      <Alert type="warning" v-if="!specList.length">
        {{ $t('dang-qian-mei-you-ke-yong-de-an-quan-gui-fan-qing-xian-qu') }}<router-link to="/dmspeclist?type=create">{{ $t('an-quan-gui-fan-ye-mian') }}</router-link>{{ $t('chuang-jian') }}
      </Alert>
      <Form ref="specForm" :model="specData" :rules="specDataValidate">
        <FormItem prop="check_spec_id" label="">
          <Select v-model="specData.check_spec_id">
            <Option v-for="spec in specList" :value="spec.specId" :key="spec.specId"
                    :disabled="!spec.enable">{{ spec.name }}({{ spec.description }})
            </Option>
          </Select>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button @click="handleCloseEnableSpecModal">{{ $t('qu-xiao') }}</Button>
        <Button type="primary" @click="handleBindSpec">{{ $t('que-ding') }}</Button>
      </div>
    </Modal>
    <Modal v-model="showDisableSpecModal" :title="$t('an-quan-gui-fan-guan-bi-ti-shi')"
           @on-ok="handleDisableSpec" @on-cancel="handleCloseDisableSpecModal">
      {{ $t('que-ren-ting-yong') }}<b>{{ currentEnv.envName }}</b>{{
        $t('shang-de-an-quan-gui-fan')
      }}
    </Modal>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import {
  queryById
} from '@/services/cc/api/rdpDs';
import DataSourceIcon from '@/components/function/DataSourceIcon';
import DataSourceHeader from '@/components/function/addDataSource/DataSourceHeader';
import DataSourceInDetail from '@/components/function/addDataSource/DataSourceInDetail';
import {
  DATASOURCE_DEPLOY_TYPE_I18N
} from '@/const';
import EnableQueryModal from '@/components/function/addDataSource/EnableQueryModal';
import { Modal } from 'ant-design-vue';
import Mapping from '../../util';

export default {
  name: 'DataSource',
  components: {
    DataSourceIcon,
    DataSourceHeader,
    DataSourceInDetail,
    EnableQueryModal
  },
  mounted() {
    this.listEnv();
  },
  data() {
    return {
      templateList: [],
      ticketData: {
        ticket_approval_type: '',
        template_id: ''
      },
      ticketDataValidate: {
        ticket_approval_type: [{ required: true, message: '引擎不能为空' }],
        template_id: [{ required: true, message: '模版不能为空' }]
      },
      specData: {
        check_spec_id: ''
      },
      specDataValidate: {
        check_spec_id: [{ required: true, message: '安全规范不能为空' }]
      },
      specList: {},
      ticketTypeList: [],
      showEnableSpecModal: false,
      showEnableTicketModal: false,
      showDisableSpecModal: false,
      showDisableTicketModal: false,
      activeTab: 'datasource',
      showEnableQueryModal: false,
      showDisableQueryModal: false,
      selectedDatasource: {},
      sourceDetail: {},
      Mapping,
      currentEnv: {},
      envData: [],
      showEnvData: [],
      refreshLoading: false,
      page: 1,
      size: 20,
      total: 0,
      searchKey: {
        host: '',
        region: '',
        dbType: 'all'
      },
      dataSourceColumn: [
        {
          title: this.$t('shu-ju-yuan-id'),
          key: 'instanceId',
          slot: 'instanceId',
          width: 280
        },
        {
          title: this.$t('lei-xing'),
          key: 'dataSourceType',
          width: 80,
          slot: 'dataSourceType'
        },
        {
          title: this.$t('shu-ju-guan-li'),
          key: 'enableQuery',
          width: 100,
          slot: 'enableQuery'
        },
        {
          title: 'Host',
          key: 'host',
          minWidth: 500,
          slot: 'host'
        },
        {
          title: this.$t('huan-jing'),
          key: 'dsEnvName',
          width: 200
        },
        {
          title: this.$t('cao-zuo'),
          key: '',
          slot: 'action',
          width: 160,
          fixed: 'right'
        }
      ],
      envColumns: [
        {
          title: this.$t('huan-jing-ming-cheng'),
          key: 'envName'
        },
        {
          title: this.$t('huan-jing-miao-shu'),
          key: 'envDesc'
        },
        {
          title: this.$t('an-quan-gui-fan'),
          slot: 'enableSpec'
        },
        {
          title: this.$t('gong-dan'),
          slot: 'enableTicket'
        }
      ],
      envPage: {
        size: 1,
        num: 20
      },
      dataSourceColumnWithNoManage: [
        {
          title: this.$t('shu-ju-yuan-id'),
          key: 'instanceId',
          slot: 'instanceId',
          width: 280
        },
        {
          title: this.$t('lei-xing'),
          key: 'dataSourceType',
          width: 80,
          slot: 'dataSourceType'
        },
        // {
        //   title: this.$t('shu-ju-yuan-miao-shu'),
        //   key: 'instanceDesc',
        //   minWidth: 280
        // },
        {
          title: 'Host',
          key: 'host',
          minWidth: 500,
          slot: 'host'
        },
        {
          title: this.$t('huan-jing'),
          key: 'dsEnvName',
          width: 120
        }
      ],
      dataSourceData: [],
      showData: []
    };
  },
  computed: {
    ifSupported() {
      return (record) => {
        let ifUnSupported = false;
        let globalDsSetting = {};
        if (this.getDmProductClusterList.length > 0) {
          this.productClusterList.forEach((cluster) => {
            if (cluster.clusterCode === this.selectDmProductCluster) {
              globalDsSetting = cluster.config.dsSettingDef;
            }
          });
        } else {
          globalDsSetting = this.dmGlobalSetting.dsSettingDef;
        }
        if (globalDsSetting && globalDsSetting[record.dataSourceType]) {
          ifUnSupported = true;
        }
        return ifUnSupported;
      };
    },
    getDmProductClusterList() {
      const ccList = [];
      this.productClusterList.forEach((cluster) => {
        if (cluster.product === 'CloudDM') {
          ccList.push(cluster);
        }
      });
      return ccList;
    },
    ...mapState(['productClusterList', 'myAuth', 'deployEnvListMap', 'clusterListMap', 'userInfo', 'regionList', 'globalDsSetting', 'dmGlobalSetting', 'selectDmProductCluster']),
    ...mapGetters([
      'includesCC',
      'includesDM',
      'isDesktop'
    ]),
    getColumns() {
      if (this.myAuth.includes('DM_DS_MANAGE')) {
        return this.dataSourceColumn;
      } else {
        return this.dataSourceColumnWithNoManage;
      }
    },
    DATASOURCE_DEPLOY_TYPE_I18N() {
      return DATASOURCE_DEPLOY_TYPE_I18N;
    },
    getEnvById() {
      return (id) => {
        let envName = '';
        this.envData.forEach((env) => {
          if (env.envId === id) {
            envName = env.envName;
          }
        });
        return envName;
      };
    }
  },
  methods: {
    handleEditParams(row) {
      this.$router.push({ path: `/dmdatasource/params/${row.id}` });
    },
    async handleApprovalTypeChange() {
      if (this.ticketData.ticket_approval_type) {
        const res = await this.$services.listTemplates({
          data: {
            uid: this.userInfo.uid,
            approvalType: this.ticketData.ticket_approval_type
          }
        });

        if (res.success) {
          this.templateList = res.data;
          if (this.templateList && this.templateList.length) {
            this.ticketData.template_id = this.templateList[0].templateIdentity;
          }
        }
      }
    },
    async handleRefreshTemplates() {
      await this.$services.refreshTemplates({
        data: {
          uid: this.userInfo.uid,
          approvalType: this.ticketData.ticket_approval_type
        }
      });
    },
    async handleEnableSpecChange(env, e) {
      this.currentEnv = env;
      if (e) {
        this.showEnableSpecModal = true;
        const res = await this.$services.listSpec({
          data: {}
        });
        if (res.success) {
          this.specList = res.data;
          if (this.specList && this.specList.length) {
            for (let i = 0; i < this.specList.length; i++) {
              const spec = this.specList[i];
              if (spec.enable) {
                this.specData.check_spec_id = spec.specId;
                break;
              }
            }
          }
        }
      } else {
        this.showDisableSpecModal = true;
      }
    },
    async handleBindSpec() {
      this.$refs.specForm.validate(async (valid) => {
        console.log(valid);
        if (valid) {
          const res = await this.$services.bindEnvParam({
            data: {
              envId: this.currentEnv.envId,
              paramKey: 'check_spec_id',
              paramValue: this.specData.check_spec_id
            }
          });

          if (res.success) {
            let specName = '';
            this.specList.forEach((spec) => {
              if (spec.specId === this.specData.check_spec_id) {
                specName = spec.name;
              }
            });
            this.$Message.success(`环境${this.currentEnv.envName}上已启用${specName}作为安全规范`);
            this.handleCloseModal();
            await this.listEnv();
          }
        }
      });
    },
    async handleDisableSpec() {
      const res = await this.$services.unbindEnvParam({
        data: {
          envId: this.currentEnv.envId,
          paramKey: 'check_spec_id'
        }
      });

      if (res.success) {
        this.$Message.success(`环境${this.currentEnv.envName}上的安全规范已停用`);
        this.handleCloseModal();
        await this.listEnv();
      }
    },
    handleCloseEnableSpecModal() {
      this.currentEnv.secDesVO.openSec = false;
      this.handleCloseModal();
    },
    handleCloseDisableSpecModal() {
      this.currentEnv.secDesVO.openSec = true;
      this.handleCloseModal();
    },
    async handleEnableTicketChange(env, e) {
      this.currentEnv = env;
      if (e) {
        this.showEnableTicketModal = true;
        const res = await this.$services.ticketType2();
        if (res.success) {
          this.ticketTypeList = res.data;
          if (this.ticketTypeList && this.ticketTypeList.length) {
            this.ticketData.ticket_approval_type = this.ticketTypeList[0].approvalType;
            await this.handleApprovalTypeChange();
          }
        }
      } else {
        this.showDisableTicketModal = true;
      }
    },
    async handleBindTicket() {
      this.$refs.ticketForm.validate(async (valid) => {
        if (valid) {
          const res = await this.$services.bindEnvParam({
            data: {
              envId: this.currentEnv.envId,
              paramKey: 'template_id',
              paramValue: this.ticketData.template_id
            }
          });

          if (res.success) {
            await this.handleRefreshTemplates();
            let templateName = '';
            this.templateList.forEach((template) => {
              if (template.templateIdentity === this.ticketData.template_id) {
                templateName = template.approTemplateName;
              }
            });
            this.$Message.success(`环境${this.currentEnv.envName}可以使用${templateName}向工单系统发起审批流程`);
            this.handleCloseModal();
            await this.listEnv();
          }
        }
      });
    },
    async handleDisableTicket() {
      const res = await this.$services.unbindEnvParam({
        data: {
          envId: this.currentEnv.envId,
          paramKey: 'template_id'
        }
      });

      if (res.success) {
        this.$Message.success(`工单系统不再允许来自${this.currentEnv.envName}新的工单请求`);
        this.handleCloseModal();
        await this.listEnv();
      }
    },
    handleCloseEnableTicketModal() {
      this.currentEnv.ticketDesVO.openTicket = false;
      this.handleCloseModal();
    },
    handleCloseDisableTicketModal() {
      this.currentEnv.ticketDesVO.openTicket = true;
      this.handleCloseModal();
    },
    handleCloseModal() {
      this.showEnableQueryModal = false;
      this.showEnableSpecModal = false;
      this.showEnableTicketModal = false;
      this.ticketData = {
        ticket_approval_type: '',
        template_id: ''
      };
      this.specData = {
        check_spec_id: ''
      };
    },
    async listEnv() {
      const data = {
        envName: ''
      };
      this.refreshLoading = true;
      const res = await this.$services.listEnvParamForSec({ data });
      this.refreshLoading = false;
      if (res.success) {
        this.envData = res.data;
        this.showEnvData = this.envData.slice((this.envPage.size - 1) * this.envPage.num, this.envPage.size * this.envPage.num);
      }
    },
    handleEnableQuery(row) {
      this.selectedDatasource = row;
      if (row.enableQuery) {
        this.showEnableQueryModal = true;
      } else {
        this.showDisableQueryModal = true;
      }
    },
    async getDataSourceList(searchKey) {
      searchKey = this.searchKey;
      this.refreshLoading = true;
      let type = null;
      let hostType = null;
      let
        deployType = null;

      if (searchKey && searchKey.dbType !== 'all') {
        type = searchKey.dbType;
      }
      if (searchKey && searchKey.hostType !== 'all') {
        hostType = searchKey.hostType;
      }
      if (searchKey && searchKey.deployType !== 'all') {
        deployType = searchKey.deployType;
      }
      const res = await this.$services.getDmDsList({
        data: {
          useVisibility: true,
          type,
          deployType,
          hostType,
          instanceIdLike: searchKey ? searchKey.instanceIdLike : null,
          dataSourceDescLike: searchKey ? searchKey.dataSourceDescLike : null,
          dsHostLike: searchKey ? searchKey.dsHostLike : null,
          dataSourceId: searchKey ? searchKey.dataSourceId : null
        }
      });
      this.refreshLoading = false;
      if (res.success) {
        this.dataSourceData = res.data;
        this.total = this.dataSourceData.length;
        this.showData = this.dataSourceData.slice((this.page - 1) * this.size, this.page * this.size);
        this.showData.map((item) => {
          item.showEditDesc = false;
          return null;
        });
      }
    },
    handlePageChange(page) {
      this.page = page;
      this.showData = this.dataSourceData.slice((this.page - 1) * this.size, this.page * this.size);
    },
    handlePageSizeChange(size) {
      this.size = size;
      this.handlePageChange(1);
    },
    handleEnvPageChange(page) {
      this.envPage.num = page;
      this.showEnvData = this.envData.slice((this.envPage.num - 1) * this.envPage.size, this.envPage.num * this.envPage.size);
    },
    handleEnvPageSizeChange(size) {
      this.envPage.size = size;
      this.handleEnvPageChange(1);
    },
    getDataSourceDetail(row) {
      queryById({ dataSourceId: row.id })
        .then((res) => {
          if (res.data.code === '1') {
            this.sourceDetail = res.data.data;
            this.accountInfo.account = this.sourceDetail.accountName;
            this.accountInfo.securityType = this.sourceDetail.securityType;
          }
        });
    },
    handleChangeSearchType() {
      // 切换查询类型的时候，重置所有搜索的值
      this.searchKey = {};
    },
    handleCancelDisableQueryModal() {
      this.selectedDatasource.enableQuery = true;
    },
    async handleDisableDsQuery() {
      const res = await this.$services.disableDsQuery({
        data: {
          dataSourceId: this.selectedDatasource.id
        }
      });
      if (res.success) {
        this.$Message.success(`数据源${this.selectedDatasource.instanceId}的数据管理已经停用`);
        await this.getDataSourceList();
      }
    }
  }
};
</script>
<style lang="less" scoped>
.datasource-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .datasource-content {
    flex: 1;
    padding: 16px;
    min-height: 0;
    display: flex;
    flex-direction: column;
    height: 100%;

    .datasource {
      flex: 1;
      min-height: 0;
      display: flex;
      flex-direction: column;

      .data-source-container {
        flex: 1;

        .iconfont {
          color: #8D95A6;
          cursor: pointer;
          font-size: 12px;
        }

        .show-datasource-info-icon {
          color: #0BB9F8;
          font-size: 20px;
          position: absolute;
          right: 0;
          top: -6px;
          cursor: pointer;
        }

        .datasource-desc-content {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          width: 200px;
        }
      }
    }

    .page-header-container {
      border-top: none;
      border-bottom: none;
    }
  }

  .footer-container {
    box-shadow: 6px 2px 23px 0 rgba(197, 197, 197, 0.5);
    height: 60px;
    background: #FAFAFA;
    text-align: center;
    line-height: 60px;
    z-index: 99;
  }
}

.add-white-list-container {
  width: 280px;
  border: 1px solid #DADADA;
  padding: 0 12px;
  border-radius: 4px;
}

.host-type {
  padding: 12px 0;
}

.host-type-label {
  font-size: 12px;
  color: #333;
  background-color: #DEEFFF;
  display: inline-block;
  //width: 16px;
  height: 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 16px;
  margin-right: 4px;
}

.alarm-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  /*border-radius: 50%;*/
  /*background-color: #FF6E0D;*/
  color: #FF6E0D;
  text-align: center;
  line-height: 20px;
  cursor: pointer;
  margin-left: 4px;

  .iconyibuforce {
    font-size: 14px;
    color: #FF6E0D;
  }
}

.datasource-operation-dropdown {
  padding: 0 !important;
}

.dropdown-content {
  padding: 7px 16px;
  display: block;
}

.active {
  color: #52C41A;
}

.inactive {
  color: #aaaaaa;
}
</style>

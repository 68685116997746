<template>
  <div class="spec-detail">
    <div class="table-list-layout">
      <div class="table-list">
        <div class="header">
          <Breadcrumb v-if="breadcrumbList.length">
            <BreadcrumbItem v-for="breadcrumb in breadcrumbList" :to="breadcrumb.to"
                            :key="breadcrumb.label">{{ breadcrumb.label }}
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
        <Tabs v-model="activeTab" @on-click="handleTabClick">
          <TabPane :label="$t('cha-xun-gui-ze')" name="QUERY">
          </TabPane>
          <TabPane :label="$t('tuo-min-gui-ze')" name="SENSITIVE"></TabPane>
        </Tabs>
        <div class="content" v-if="isQuery">
          <div class="option">
            <div class="left">
              <Input v-model="QUERY.search" style="width: 280px;margin-right: 10px;" clearable></Input>
              <Button @click="handleRuleSearch" type="primary">{{ $t('cha-xun') }}</Button>
            </div>
            <div class="right">
              <Button @click="getSpecDetail">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table border :columns="QUERY.ruleColumns" :data="QUERY.showRuleList" size="small"
                   :loading="QUERY.loading">
              <template slot-scope="{ row }" slot="targetType">
                {{ getTargetType(row.targetType).i18n }}
              </template>
              <template slot-scope="{ row }" slot="ruleAction">
                <Button @click="handleGoRange(row)" type="text" size="small" :disabled="!row.enable">
                  {{ $t('fan-wei') }}
                </Button>
                <Button @click="handleViewRuleDetail(row)" type="text" size="small">
                  {{ $t('xiang-qing') }}
                </Button>
                <Button @click="handleEditRuleDetail(row)" type="text" size="small" v-if="myAuth.includes('DM_SECRULES_MANAGE')">
                  {{ $t('she-zhi') }}
                </Button>
              </template>
              <template slot-scope="{ row }" slot="dsRange">
                <Tooltip :content="ds" v-for="ds in row.dsRange" :key="ds" transfer placement="top">
                  <cc-svg-icon :name="ds" style="margin-right: 5px;"/>
                </Tooltip>
              </template>
              <template slot-scope="{ row }" slot="warnLevel">
                <Tag :color="row.warnLevel === 'SUGGEST' ? 'warning' : 'error'">
                  {{ RULE_WARN_LEVEL[row.warnLevel] }}
                </Tag>
              </template>
              <template slot-scope="{ row }" slot="enable">
                <i-switch v-model="row.enable" true-color="#52C41A"
                          @on-change="handleEnableSpecRule(row, 'enable', false)"/>
              </template>
            </Table>
          </div>
        </div>
        <div class="content" v-else>
          <div class="option">
            <div class="left">
              <Input v-model="SENSITIVE.search" style="width: 280px;margin-right: 10px;" clearable></Input>
              <Button @click="handleRuleSearch" type="primary">{{ $t('cha-xun') }}</Button>
            </div>
            <div class="right">
              <Button @click="getSpecDetail">{{ $t('shua-xin') }}</Button>
            </div>
          </div>
          <div class="table">
            <Table border :columns="SENSITIVE.ruleColumns" :data="SENSITIVE.showRuleList"
                   size="small" :loading="SENSITIVE.loading">
              <template slot-scope="{ row }" slot="senMode">
                {{ getSenMode(row.senMode).i18n }}
              </template>
              <template slot-scope="{ row }" slot="ruleAction">
                <Button @click="handleGoRange(row)" type="text" size="small"
                        v-if="row.enable">{{ $t('fan-wei') }}
                </Button>
                <Button @click="handleViewRuleDetail(row)" type="text" size="small">
                  {{ $t('xiang-qing') }}
                </Button>
                <Button @click="handleEditRuleDetail(row)" type="text" size="small" v-if="myAuth.includes('DM_SECRULES_MANAGE')">{{
                    $t('she-zhi')
                  }}
                </Button>
              </template>
              <template slot-scope="{ row }" slot="enable">
                <i-switch v-model="row.enable" true-color="#52C41A"
                          @on-change="handleEnableSpecRule(row, 'enable', false)"/>
              </template>
            </Table>
          </div>
        </div>
      </div>
      <div class="footer">
        <Page :total="QUERY.total" show-total show-elevator @on-change="handlePageChange"
              v-if="isQuery"
              show-sizer
              v-model="QUERY.pageNum"
              :page-size="QUERY.pageSize" @on-page-size-change="handlePageSizeChange"/>
        <Page :total="SENSITIVE.total" show-total show-elevator @on-change="handlePageChange" v-else
              show-sizer
              v-model="SENSITIVE.pageNum"
              :page-size="QUERY.pageSize" @on-page-size-change="handlePageSizeChange"/>
      </div>
    </div>
    <Modal
      v-model="showRuleDetailModal"
      :title="$t('gui-ze-xiang-qing')"
      @on-cancel="handleCloseModal"
      width="800"
    >
      <Form :label-width="60">
        <FormItem :label="$t('ming-cheng')">
          <div>{{ selectedRule.ruleName }}</div>
        </FormItem>
        <FormItem :label="$t('miao-shu')">
          <div>{{ selectedRule.ruleDesc }}</div>
        </FormItem>
        <FormItem :label="$t('fan-wei')" v-if="selectedRule.targetType">
          <div>{{ selectedRule.targetType }}</div>
        </FormItem>
        <FormItem :label="$t('nei-rong')">
          <read-only-editor :text="selectedRule.ruleContent" :max-height="200"/>
        </FormItem>
        <FormItem :label="$t('shu-ju-yuan')" v-if="selectedRule.dsRange">
          <cc-svg-icon v-for="ds in selectedRule.dsRange" :name="ds" style="margin-right: 5px;"
                       :key="ds"/>
        </FormItem>
        <FormItem :label="$t('can-shu')" v-if="specRuleParamList.length">
          <Table :columns="specRuleParamColumns" :data="specRuleParamList" size="small" border>
            <div slot="value" slot-scope="{ row }">
              {{ row.value }}
            </div>
          </Table>
        </FormItem>
      </Form>
    </Modal>
    <Modal
      v-model="showEditSpecRuleModal"
      :title="$t('she-zhi')"
      @on-ok="handleEditRule('edit', false)"
      @on-cancel="handleCloseModal"
      width="800"
    >
      <Form :label-width="80">
        <FormItem :label="$t('deng-ji')" v-if="selectedRule.ruleKind === 'QUERY'">
          <Select v-model="selectedRule.warnLevel" style="width: 200px;">
            <Option key="SUGGEST" value="SUGGEST">{{ $t('ti-shi') }}</Option>
            <Option key="FAILURE" value="FAILURE">{{ $t('zu-sai') }}</Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('tuo-min-fang-shi')" v-if="selectedRule.ruleKind === 'SENSITIVE'">
          <Select v-model="selectedRule.senMode" style="width: 200px;">
            <Option v-for="sen in ruleSetting.senConf.senMode" :key="sen.name" :value="sen.name">{{ sen.i18n }}</Option>
          </Select>
        </FormItem>
        <FormItem :label="$t('can-shu')">
          <Table :columns="specRuleParamColumns" :data="specRuleParamList" size="small" border
                 height="300">
            <div slot="value" slot-scope="{ row }">
              <Input v-model="row.value" @on-change="handleEditSpecRuleParam(row.name, $event)"/>
            </div>
          </Table>
        </FormItem>
      </Form>
    </Modal>
    <Modal v-model="showForceRuleModal" :title="forceRuleModalTitle">
      <div class="title" v-html="forceRuleModalText" style="margin-bottom: 10px;"></div>
      <Table :columns="forceRuleRefererColumns" :data="forceRuleRefererList" size="small">
      </Table>
      <div slot="footer">
        <Button @click="handleCloseModal">{{ $t('qu-xiao') }}</Button>
        <Button type="error" @click="handleEditRule(forceEvent.type, true)" v-if="forceRuleModalTitle">{{forceRuleModalTitle }}</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { RULE_WARN_LEVEL } from '@/utils';
import ReadOnlyEditor from '@/components/editor/ReadOnlyEditor';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'SpecDetail',
  components: { ReadOnlyEditor },
  mounted() {
    this.specId = this.$route.params.specId;
    if (this.$route.query && this.$route.query.ruleKind) {
      this.activeTab = this.$route.query.ruleKind;
    }
    this.getRuleSetting();
    this.getSpecDetail();
  },
  computed: {
    ...mapState(['ruleSetting', 'myAuth']),
    ...mapGetters(['getTargetType', 'getSenMode']),
    isQuery() {
      return this.activeTab === 'QUERY';
    },
    breadcrumbList() {
      return [{
        label: this.$t('gui-fan-lie-biao'),
        to: '/dmspeclist'
      }, { label: this.specDetail.specName }];
    }
  },
  data() {
    return {
      activeTab: 'QUERY',
      specId: '',
      showData: [],
      ruleListLoading: false,
      ruleParam: {},
      forceEvent: {
        type: '',
        data: {}
      },
      showForceRuleModal: false,
      forceRuleModalTitle: '',
      forceRuleModalText: '',
      forceRuleRefererList: [],
      forceRuleRefererColumns: [
        {
          title: this.$t('huan-jing-ming-cheng'),
          key: 'envName'
        },
        {
          title: this.$t('huan-jing-miao-shu'),
          key: 'envDesc'
        }
      ],
      showEditSpecRuleModal: false,
      specRuleParamColumns: [
        {
          title: this.$t('ming-cheng'),
          key: 'name'
        },
        {
          title: this.$t('miao-shu'),
          key: 'hint'
        },
        {
          title: this.$t('zhi'),
          slot: 'value'
        }
      ],
      specRuleParamList: [],
      RULE_WARN_LEVEL,
      specDetail: {
        specName: '',
        specDesc: ''
      },
      showRuleDetailModal: false,
      selectedRule: {},
      supportTargetList: [],
      QUERY: {
        loading: false,
        pageSize: 20,
        pageNum: 1,
        total: 0,
        search: '',
        allRuleList: [],
        ruleList: [],
        showRuleList: [],
        ruleColumns: [
          {
            title: this.$t('gui-ze-ming-cheng'),
            key: 'ruleName',
            width: 200
          },
          {
            title: this.$t('gui-ze-miao-shu'),
            key: 'ruleDesc'
          },
          {
            title: this.$t('shi-yong-shu-ju-yuan'),
            slot: 'dsRange',
            width: 200
          },
          {
            title: this.$t('dui-xiang-lei-xing'),
            slot: 'targetType',
            align: 'center',
            width: 86
          },
          {
            title: this.$t('deng-ji'),
            slot: 'warnLevel',
            width: 82
          },
          {
            title: this.$t('qi-yong'),
            slot: 'enable',
            width: 80,
            fixed: 'right'
          },
          {
            title: this.$t('cao-zuo'),
            slot: 'ruleAction',
            width: 160,
            fixed: 'right'
          }
        ]
      },
      SENSITIVE: {
        loading: false,
        pageSize: 20,
        pageNum: 1,
        total: 0,
        search: '',
        allRuleList: [],
        ruleList: [],
        showRuleList: [],
        ruleColumns: [
          {
            title: this.$t('gui-ze-ming-cheng'),
            key: 'ruleName',
            width: 200
          },
          {
            title: this.$t('gui-ze-miao-shu'),
            key: 'ruleDesc'
          },
          {
            title: '脱敏方式',
            width: 100,
            slot: 'senMode'
          },
          {
            title: this.$t('qi-yong'),
            slot: 'enable',
            width: 80,
            fixed: 'right'
          },
          {
            title: this.$t('cao-zuo'),
            slot: 'ruleAction',
            width: 160,
            fixed: 'right'
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions(['getRuleSetting']),
    handlePageChange(pageNum) {
      this[this.activeTab].pageNum = pageNum;
      this.setTableShowData();
    },
    handlePageSizeChange(pageSize) {
      this[this.activeTab].pageSize = pageSize;
      this.handlePageChange(1);
    },
    handleTabClick(name) {
      this.$router.replace({ query: { ruleKind: name } });
      if (!this[name].total) {
        this.getSpecDetail();
      }
    },
    handleEditSpecRuleParam(name, e) {
      this.ruleParam[name] = e.target.value;
    },
    handleEnableSpecRule(rule, type, force) {
      this.selectedRule = rule;
      this.handleEditRule(type, force);
    },
    async handleEditRule(type, force = false) {
      const {
        ruleId, enable, warnLevel, parameterValue, ruleKind, senMode
      } = this.selectedRule;
      const data = {
        specId: this.specId,
        force,
        rule: {
          ruleKind,
          senMode,
          enable,
          ruleId,
          warnLevel,
          ruleParam: type === 'enable' ? parameterValue : this.ruleParam
        }
      };
      const res = await this.$services.specSaveRule({
        data
      });

      if (res.success) {
        this.showRuleDetailModal = false;
        if (res.data) {
          if (res.data.success) {
            await this.handleCloseModal();
            await this.getSpecDetail();
            this.$Message.success(res.data.message);
          } else {
            this.forceEvent = {
              type,
              data
            };
            this.showForceRuleModal = true;
            this.forceRuleModalTitle = type === 'enable'
              ? enable ? this.$t('qiang-zhi-qi-yong') : this.$t('qiang-zhi-guan-bi')
              : this.$t('qiang-zhi-xiu-gai');
            this.forceRuleModalText = res.data.message;
            this.forceRuleRefererList = res.data.referer;
          }
        }
      }
    },
    async handleCloseModal() {
      this.showForceRuleModal = false;
      this.showRuleDetailModal = false;
      this.showEditSpecNameModal = false;
      this.showEditSpecDescModal = false;
      this.showEditSpecRuleModal = false;
      this.newSpecName = '';
      this.newSpecDesc = '';
    },
    handleRuleSearch() {
      this[this.activeTab].ruleList = this[this.activeTab].allRuleList.filter((rule) => rule.ruleName.includes(this[this.activeTab].search) || rule.ruleDesc.includes(this[this.activeTab].search));
      this[this.activeTab].total = this[this.activeTab].ruleList.length;
      this.handlePageSizeChange(this[this.activeTab].pageSize);
    },
    async getSpecDetail() {
      this[this.activeTab].loading = true;
      const res = await this.$services.specDetail({
        data: {
          specId: this.specId,
          ruleKind: this.activeTab
        }
      });

      this[this.activeTab].loading = false;
      if (res.success) {
        this.specDetail = res.data;
        this[this.activeTab].search = '';
        this[this.activeTab].allRuleList = res.data.ruleList;
        this[this.activeTab].ruleList = res.data.ruleList;
        this[this.activeTab].total = res.data.ruleList.length;
        this.setTableShowData();
      }
    },
    setTableShowData() {
      const { pageNum, pageSize } = this[this.activeTab];
      this[this.activeTab].showRuleList = this[this.activeTab].ruleList.slice((pageNum - 1) * pageSize, pageNum * pageSize);
    },
    generateParamList() {
      const { parameterDef, parameterValue } = this.selectedRule;
      const specRuleParamList = [];
      parameterDef.forEach((def) => {
        this.ruleParam[def.name] = parameterValue ? parameterValue[def.name] : null;
        specRuleParamList.push({ ...def, value: parameterValue ? parameterValue[def.name] : null });
      });
      this.specRuleParamList = specRuleParamList;
    },
    handleGoRange(rule) {
      this.$router.push({
        path: `/dmspec/${this.specId}/rule/${rule.ruleId}/range`,
        query: {
          ruleKind: this.activeTab,
          specName: this.specDetail.specName,
          ruleName: rule.ruleName
        }
      });
    },
    async handleViewRuleDetail(rule) {
      await this.getSpecRuleDetail(rule);
      this.generateParamList();
      this.showRuleDetailModal = true;
    },
    async getSpecRuleDetail(rule) {
      const res = await this.$services.specRuleDetail({
        data: {
          specId: this.specDetail.specId,
          ruleId: rule.ruleId,
          ruleKind: this.activeTab
        }
      });

      if (res.success) {
        this.selectedRule = res.data;
      }
    },
    async handleEditRuleDetail(rule) {
      await this.getSpecRuleDetail(rule);
      this.generateParamList();
      this.showEditSpecRuleModal = true;
    }
  }
};
</script>
<style lang="less" scoped>
/deep/ .ivu-form-item {
  margin-bottom: 10px;
}

.spec-detail {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
